<template>
  <div class="main-card">
    <h5 class="main-card-title">موقعیت متخصص ها را روی نقشه می توانید مشاهده کنید</h5>
    <div class="map-box">
      <div id="map" ref="map" />
      <geocode-search
        @selectedGeocode="geocodeFind"
        style="right: 17vw; top: 1vh"
      ></geocode-search>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import GeocodeSearch from "../../../components/map/GeocodeSearch.vue";
export default {
  name: "advicerMap",
  components: {
    GeocodeSearch,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      map: null,
    };
  },
  watch: {},
  methods: {
    // ...mapActions("dietPlan", ["GetAllDietPlanUserFarms"]),

    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh",
      center: [53.12609431323281, 33.59332186046887],
      zoom: 4,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true,
      },
      defaultMode: "draw_polygon",
    });
    this.map.on("load", function () {});
  },
};
</script>

<style scoped>
 
.main-card-title {
  font-size: 1em;
  margin: 6px 0px;
  text-align: right;
  color: #1b5e20;
  font-weight: bold;
}
#map {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.map-box {
  width: 100%;
  height: 60vh;
  position: relative;
}


</style>