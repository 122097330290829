<template>
  <div class="main-card main-card-finder">
    <CRow class="w-100 search-box">
      <CInput
        type="text"
        v-model="farmName"
        placeholder="جستجو در متخصصان سامانه ... "
        class="search-input mb-0"
      />
      <CButton class="search-btn" color="success">
        <i class="fa fa-filter" aria-hidden="true"></i>
      </CButton>
    </CRow>
    <CRow class="w-100 advicer-list">
      <div class="advicer-list-item">
        <h4>
          <i class="fa fa-user-o" aria-hidden="true"></i>
          حسین میر اکبرزاده
        </h4>
        <h4>
          <i class="fa fa-phone" aria-hidden="true"></i>
          09154156281
        </h4>
        <h4>
          <i class="fa fa-address-card-o" aria-hidden="true"></i>
          کارشناسی / 12 ماه سابقه کار
        </h4>
        <hr class="w-100" />
        <div class="item-action">
          <ul>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص اول</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص دوم دوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص سوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص چهارم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص پنجم</li>
          </ul>
          <div>
            <CButton class="action-btn" color="info">
              <i class="fa fa-address-card-o" aria-hidden="true"></i>
            </CButton>
            <CButton class="action-btn" color="info">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </CButton>
          </div>
        </div>
      </div>
      <div class="advicer-list-item">
        <h4>
          <i class="fa fa-user-o" aria-hidden="true"></i>
          حسین میر اکبرزاده
        </h4>
        <h4>
          <i class="fa fa-phone" aria-hidden="true"></i>
          09154156281
        </h4>
        <h4>
          <i class="fa fa-address-card-o" aria-hidden="true"></i>
          کارشناسی / 12 ماه سابقه کار
        </h4>
        <hr class="w-100" />
        <div class="item-action">
          <ul>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص اول</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص دوم دوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص سوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص چهارم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص پنجم</li>
          </ul>
          <div>
            <CButton class="action-btn" color="info">
              <i class="fa fa-address-card-o" aria-hidden="true"></i>
            </CButton>
            <CButton class="action-btn" color="info">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </CButton>
          </div>
        </div>
      </div>
      <div class="advicer-list-item">
        <h4>
          <i class="fa fa-user-o" aria-hidden="true"></i>
          حسین میر اکبرزاده
        </h4>
        <h4>
          <i class="fa fa-phone" aria-hidden="true"></i>
          09154156281
        </h4>
        <h4>
          <i class="fa fa-address-card-o" aria-hidden="true"></i>
          کارشناسی / 12 ماه سابقه کار
        </h4>
        <hr class="w-100" />
        <div class="item-action">
          <ul>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص اول</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص دوم دوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص سوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص چهارم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص پنجم</li>
          </ul>
          <div>
            <CButton class="action-btn" color="info">
              <i class="fa fa-address-card-o" aria-hidden="true"></i>
            </CButton>
            <CButton class="action-btn" color="info">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </CButton>
          </div>
        </div>
      </div>
      <div class="advicer-list-item">
        <h4>
          <i class="fa fa-user-o" aria-hidden="true"></i>
          حسین میر اکبرزاده
        </h4>
        <h4>
          <i class="fa fa-phone" aria-hidden="true"></i>
          09154156281
        </h4>
        <h4>
          <i class="fa fa-address-card-o" aria-hidden="true"></i>
          کارشناسی / 12 ماه سابقه کار
        </h4>
        <hr class="w-100" />
        <div class="item-action">
          <ul>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص اول</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص دوم دوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص سوم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص چهارم</li>
            <li><i class="fa fa-check" aria-hidden="true"></i>تخصص پنجم</li>
          </ul>
          <div>
            <CButton class="action-btn" color="info">
              <i class="fa fa-address-card-o" aria-hidden="true"></i>
            </CButton>
            <CButton class="action-btn" color="info">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </CButton>
          </div>
        </div>
      </div>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "advicerList",
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
    };
  },
  watch: {},
  methods: {
    // ...mapActions("dietPlan", ["GetAllDietPlanUserFarms"]),
  },
  mounted() {},
};
</script>

<style scoped>
.search-box {
  height: 10%;
  display: flex;
  justify-content: space-around;
  align-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
}
.search-input {
  flex-grow: 8;
}
.search-btn {
  padding: 0.5rem 0.2rem !important;
  flex-grow: 1;
  margin-right: 1em;
}

.advicer-list {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: 55vh;
  padding: 10px;
}
.advicer-list-item {
  display: flex;
  width: 100%;
  background: #0466c8;
  flex-direction: column;
  margin-top: 1em;
  border-radius: 10px;
  padding: 10px;
}
.advicer-list-item h4 {
  font-size: 0.9rem;
  padding: 0.2rem 0.3rem;
  color: #ffffff;
}
.advicer-list-item h4 i {
  font-size: 1rem;
  width: 1.5em;
  text-align: center;
  color: #dee4f3;
}

.item-action {
  display: flex;
  align-items: stretch;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #e0fbfc;
  border-radius: 10px;
}
.item-action ul {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: 9;
  justify-content: start;
  padding: 0px;
  margin: 0px;
}
.item-action ul li {
  list-style: none;
  margin-right: 10px;
  min-width: 34%;
  color: #37474f;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.item-action > div {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.action-btn {
  padding: 0.5rem 0.7rem !important;
  margin: 3px;
}
</style>