<template>
  <body-with-header class="h-100" title="متخصص یاب (با انتخاب متخصص پس از تایید توسط متخصص همکاری شما آغاز می شود)" :ads="true">
    <CRow class="w-100">
      <CCol md="4"><advicerList /></CCol>
      <CCol md="8"><advicerMap /></CCol>
    </CRow>
  </body-with-header>
</template>

<script>
import { mapActions } from "vuex";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import advicerList from "./subForm/advicerList.vue";
import advicerMap from "./subForm/advicerMap.vue";

export default {
  name: "findAdvicerForm",
  components: {
    BodyWithHeader,
    advicerList,
    advicerMap,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
    };
  },
  watch: {},
  methods: {
    // ...mapActions("dietPlan", ["GetAllDietPlanUserFarms"]),
  },
  mounted() {},
};
</script>

<style>
.main-card-finder {
  display: flex;
  width: 100%;
  height: 65vh;
  flex-wrap: wrap;
  flex-direction: column;
}
</style>